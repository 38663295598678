<template>
  <div class="container">
    <div class="title">账户额度预警</div>
    <div class="content">
      <div class="form_box">

        <!-- 个人认证 -->
        <el-form
          ref="personalForm"
          label-width="150px"
        >
          <el-form-item label="是否开启预警" prop="amount_notice_min">
            <el-switch
              v-model="is_open">
            </el-switch>
          </el-form-item>

          <el-form-item label="可用额度预警" prop="amount_notice_min">
            <el-input v-model="amount_notice_min" :readonly="!is_open" type="number" class="notice_input">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>

          <el-form-item>
            <div class="costom_form_item">
              <div class="tips">
                <p>如开启预警，当您账户可用余额低于预警阈值时，</p>
                <p>每天短信提醒一次（最多提醒5次）</p>
              </div>
            </div>
          </el-form-item>
        </el-form>

        <div class="btn_box">
          <el-button
            type="primary"
            @click="clickSubmitBtn"
          >保 存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfoApi, setAmountNotice } from '../../../api/user'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      loading: false,
      is_open: false,
      amount_notice_min: ''
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {
    this.getAuthInfo()
  },
  mounted () { },
  // 组件方法
  methods: {
    async getAuthInfo () {
      this.loading = true
      const res = await getUserInfoApi()
      this.loading = false
      if (res && res.code === 0) {
        if (res.data.detail.amount_notice_min > 0) {
          this.is_open = true
          this.amount_notice_min = res.data.detail.amount_notice_min
        }
      }
    },
    async clickSubmitBtn () {
      const res = await setAmountNotice({
        min_amount_open: this.is_open ? 1 : 0,
        min_amount_value: this.amount_notice_min
      })
      if (res && res.code === 0) {
        this.$message.success('提交成功')
      }
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    height: calc(100% - 35px);
    overflow-y: auto;
    .form_box {
      width: 800px;
      height: 100px;
      // background-color: pink;
      margin: 20px auto;

      .auth_status_tip {
        text-align: center;
        color: rgb(236, 77, 77);
        margin-bottom: 20px;
      }
      .btn_box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }

      .tips {
        width: 50%;
        font-size: 12px;
        color: #666;
        padding-left: 10px;
        box-sizing: border-box;
        p {
          line-height: normal !important;
        }
      }

      .el-select {
        width: 50%;
      }
      .el-input {
        width: 150px;
      }

      .el-form-item {
        // margin-bottom: 15px;
      }
      .costom_form_item {
        width: 100%;
        display: flex;
        align-items: center;
      }

      /deep/.el-input.is-disabled .el-input__inner {
        color: #333;
      }
    }
  }
}
</style>
